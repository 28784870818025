.partner-container{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;  
  width: calc(100vw * var(--global-content-width) * (1/var(--partners-per-row)) - 10px);
  text-align: center;
  margin-top: 5px;
}

.partner-box{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vw * var(--global-content-width) * (1/var(--partners-per-row)) - 10px);
  box-shadow: 0px 2px 8px rgba(35, 31, 32, 0.16);
  border-radius: 8px;  
  margin-bottom: 5px;
}

.partner-box > img{
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 5px;
  object-fit: contain;
}

.partner-name{  
  width: 100%;
  font-size: 2.8vw;
  font-family: 'Roboto Condensed';
  word-wrap: break-word;
}

@media only screen and (min-width : 1280px) {
  .partner-container{
    width: calc(100vw * var(--global-content-width) * (1/var(--partners-per-row)) - 30px);
  }
  .partner-box{
    height: calc(100vw * var(--global-content-width) * (1/var(--partners-per-row)) - 30px);
    margin-bottom: 15px;
  }
  .partner-name{
    font-size: 1vw;    
  }
}