/* HOME GENERAL */

.home-content{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 20px;
}

/* PARTNERS */

.home-partners{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.home-partners-content{
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap !important;
}

/* APP STORE */

.home-app-store{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.home-app-store-content{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 4.5vw;
  font-family: 'Roboto Condensed';
  text-align: center;
}

.home-app-store-logos{
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  margin-top: 25px;
}

.home-app-store-logos-google{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  width: 45%;
}

.home-app-store-logos-apple{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  width: 45%;
  font-weight: bold;
  font-size: 2.6vw;
}

.home-app-store-logo{
  width: 100%;
}

.home-app-store-logos-apple > span{
  margin-top: 5px;
  background-color: var(--color-blue);
  color: var(--color-white);
  padding: 5px 10px 5px 10px;  
  border-radius: 5px;
}

@media only screen and (min-width : 1280px) {  
  
  /* PARTNERS */

  .home-partners{
    margin-bottom: 0px;
  }

  /* APP STORE */

  .home-app-store{
    margin-bottom: 25px;
  }

  .home-app-store-content{  
    text-align: left;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.6vw;  
    line-height: normal;  
  }

  .home-app-store-logos-apple,
  .home-app-store-logos-google{
    font-size: 0.9vw;
    width: 35%;
    margin-top: 10px;
  }
}
