.editions-content{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.editions-content > *{
  margin-bottom: 25px;
}