.footer-container{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background-color: var(--color-brand-blue);
}

.footer-content{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  font-family: 'Roboto Condensed';
  font-weight: 300;
  color: var(--color-white);
}

.footer-margin__top{
  margin-top: 25px;
}

.footer-content > * {
  margin-bottom: 25px;
}

.footer-logo{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.footer-logo > span{
  font-size: 9vw;
  font-weight: bold;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}

.footer-address{
  text-align: center;
  width: 100%;
}

.footer-address > address{
  font-style: normal; 
}

.footer-contact{
  display: flex;
  flex-flow: column;
  align-items: center;
}

.footer-contact > *{
  margin-bottom: 10px;
}

.footer-contact > a > img{
  margin-right: 8px;
}

.footer-developed-by{
  text-align: center;
}

.footer-developed-by-logo{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  margin: auto;
}

.footer-developed-by-logo > a > img{
  margin-top: 5px;
  margin-bottom: 5px;
}

@media only screen and (min-width : 1280px) {
  .footer-content{
    flex-flow: row;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .footer-content > *{
    width: 25%;
    margin-bottom: 0;
  }

  .footer-margin__top{
    margin-top: 0;
  }

  .footer-logo > span{
    font-size: 2.5vw;
  }

  .footer-contact > *{
    margin-bottom: 0px;
  }

  .footer-developed-by-logo{
    width: 35%;
  }
}