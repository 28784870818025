@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@400;700&family=Ubuntu&display=swap');

:root {
  --color-dark-blue: #204986;
  --color-brand-blue: #005A96;
  --color-blue: #518DB5;
  --color-light-blue: #A2C0D4;
  --color-action-blue: #017CEB;
  --color-light: #f3f3f3;
  --color-gray: #C4C4C4;
  --color-white: #FFF;
  --color-black: #000;
  --color-whatsapp: #20BE6D;
  --color-dark-whatsapp: #219D5D;

  --global-content-width: 0.8;
  --partners-per-row: 4;

  --media-query-min-width: 1280px;
}

body {
  margin: 0;
}

address{
  font-style: normal;
}

.global__content-width{
  width: 80%;
}

.cursor-pointer{
  cursor: pointer;
}

a:link {
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.default-page-title{
  font-weight: bold;
  font-family: 'Roboto Condensed';
  color: var(--color-dark-blue);
  font-size: 5vw;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.default-page-text{
  font-family: 'Roboto Condensed';
  font-size: 3.3vw;
  font-weight: 300;
  line-height: 4.5vw;
}

@media only screen and (min-width : 1280px) {
  :root {  
    --global-content-width: 0.6;
    --partners-per-row: 8;
  }

  .global__content-width{
    width: 60%;
  }

  .default-page-title{
    font-size: 2.4vw; 
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .default-page-text{
    font-size: 1.2vw;
    line-height: 2vw;
  }
}