.contact-and-localization-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 100%;
}

.contact-container,
.localization-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 100%;
}

.contact-content,
.localization-content{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.contact-label,
.contact-input,
.contact-textarea{
  width: 100%;  
}

.contact-input,
.contact-textarea{
  border: 2px solid var(--color-light-blue);
  border-radius: 4px;
  padding: 5px;
}

.contact-content > .contact-input{
  margin-bottom: 10px;
}

.contact-label{
  font-weight: 400;
}

.contact-buttons{
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.contact-buttons > button{
  color: var(--color-white);
  font-family: 'Roboto Condensed';
  font-weight: bold;
  padding: 8px 15px 8px 15px;
  border: none;
}

#button-whatsapp{  
  background-color: var(--color-whatsapp);
} 

#button-whatsapp:active{  
  background-color: var(--color-dark-whatsapp);
} 

#button-send-message{  
  background-color: var(--color-dark-blue);
} 

#button-send-message:active{  
  background-color: var(--color-blue);
} 

.map-marker-icon{
  width: 4vw;
}

.localization-address{
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
  font-style: normal;
}

@media only screen and (min-width : 1280px) {
  .contact-and-localization-container{
    flex-flow: row;
    align-items: flex-start;
  }

  .contact-input,
  .contact-textarea{
    padding: 0;
  }

  .contact-buttons{
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .map-marker-icon{
    width: 2vw;
  }
}