@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@400;700&family=Ubuntu&display=swap);
:root {
  --color-dark-blue: #204986;
  --color-brand-blue: #005A96;
  --color-blue: #518DB5;
  --color-light-blue: #A2C0D4;
  --color-action-blue: #017CEB;
  --color-light: #f3f3f3;
  --color-gray: #C4C4C4;
  --color-white: #FFF;
  --color-black: #000;
  --color-whatsapp: #20BE6D;
  --color-dark-whatsapp: #219D5D;

  --global-content-width: 0.8;
  --partners-per-row: 4;

  --media-query-min-width: 1280px;
}

body {
  margin: 0;
}

address{
  font-style: normal;
}

.global__content-width{
  width: 80%;
}

.cursor-pointer{
  cursor: pointer;
}

a:link {
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.default-page-title{
  font-weight: bold;
  font-family: 'Roboto Condensed';
  color: #204986;
  color: var(--color-dark-blue);
  font-size: 5vw;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.default-page-text{
  font-family: 'Roboto Condensed';
  font-size: 3.3vw;
  font-weight: 300;
  line-height: 4.5vw;
}

@media only screen and (min-width : 1280px) {
  :root {  
    --global-content-width: 0.6;
    --partners-per-row: 8;
  }

  .global__content-width{
    width: 60%;
  }

  .default-page-title{
    font-size: 2.4vw; 
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .default-page-text{
    font-size: 1.2vw;
    line-height: 2vw;
  }
}
.header-container{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background-color: var(--color-brand-blue);
  height: 75px;
}

.header-logo{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.header-logo > img{
  height: 40px;
}

.header-logo > span{
  font-family: 'Roboto Condensed';
  font-weight: bold;
  color: var(--color-light-blue);
  font-size: 12px;
}

.header-menu-container{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background-color: var(--color-light);
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.18);
  font-family: 'Roboto Condensed';  
  height: 30px;
  width: 100%;
}

.header-menu{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  font-size: 14px;
}

@media only screen and (min-width : 1280px) {
  .header-container{
    height: 130px;
  }

  .header-logo > img{   
    height: 80px;
  }

  .header-logo > span{
    font-size: 18px;
  }

  .header-menu-container{
    height: 45px;
    box-shadow: 2px 0px 5px 4px rgba(0, 0, 0, 0.18);
  }

  .header-menu-container{
    height: 2.8vw;
  }

  .header-menu{
    width: 50%;
    font-size: 1.2vw;
  }

  .header-menu-item:hover{
    font-weight: bold;
  }
}
.edition-content{
  display: flex;
  flex-flow: row;
  font-family: 'Roboto Condensed';
  color: var(--color-black);
}

.edition-thumbnail{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-right: 20px;
}

.edition-thumbnail > a > img{
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 100%;
}

.edition-details{
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  max-width: 50%;
  max-height: 100%;
}

.edition-publication-date{
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  font-weight: bold;
  font-size: 2.8vw;  
  max-height: 10%;
}

.edition-publication-date > span{
  margin-bottom: 2px;
}

.edition-publication-date-hr{
  background-color: var(--color-dark-blue);
  height: 2px;
  width: 90%;
}

.edition-title{
  font-weight: bold;
  font-size: 4.5vw;
  max-height: 80%;
  overflow-wrap: break-word;
  overflow-y: hidden;
  overflow-x: hidden;
}

.edition-read-it{
  font-weight: bold;
  font-size: 3.5vw;
  color: var(--color-dark-blue);
  max-height: 10%;
}

@media only screen and (min-width : 1280px) {
  .edition-content{
    justify-content: space-around;
  }
  .edition-thumbnail{
    width: 30%;
    margin-left: 35px;
  }
  .edition-details{
    width: 70%;
    margin-right: 35px;
  }
  .edition-publication-date{
    font-size: 1.5vw; 
  }
  .edition-publication-date-hr{
    height: 4px;
    width: 75%;
  }
  .edition-title{  
    font-size: 2.8vw;    
  }
  .edition-read-it{
    font-size: 2vw;
  }
}
.footer-container{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background-color: var(--color-brand-blue);
}

.footer-content{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  font-family: 'Roboto Condensed';
  font-weight: 300;
  color: var(--color-white);
}

.footer-margin__top{
  margin-top: 25px;
}

.footer-content > * {
  margin-bottom: 25px;
}

.footer-logo{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.footer-logo > span{
  font-size: 9vw;
  font-weight: bold;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}

.footer-address{
  text-align: center;
  width: 100%;
}

.footer-address > address{
  font-style: normal; 
}

.footer-contact{
  display: flex;
  flex-flow: column;
  align-items: center;
}

.footer-contact > *{
  margin-bottom: 10px;
}

.footer-contact > a > img{
  margin-right: 8px;
}

.footer-developed-by{
  text-align: center;
}

.footer-developed-by-logo{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  margin: auto;
}

.footer-developed-by-logo > a > img{
  margin-top: 5px;
  margin-bottom: 5px;
}

@media only screen and (min-width : 1280px) {
  .footer-content{
    flex-flow: row;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .footer-content > *{
    width: 25%;
    margin-bottom: 0;
  }

  .footer-margin__top{
    margin-top: 0;
  }

  .footer-logo > span{
    font-size: 2.5vw;
  }

  .footer-contact > *{
    margin-bottom: 0px;
  }

  .footer-developed-by-logo{
    width: 35%;
  }
}
.partner-container{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;  
  width: calc(100vw * var(--global-content-width) * (1/var(--partners-per-row)) - 10px);
  text-align: center;
  margin-top: 5px;
}

.partner-box{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vw * var(--global-content-width) * (1/var(--partners-per-row)) - 10px);
  box-shadow: 0px 2px 8px rgba(35, 31, 32, 0.16);
  border-radius: 8px;  
  margin-bottom: 5px;
}

.partner-box > img{
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 5px;
  object-fit: contain;
}

.partner-name{  
  width: 100%;
  font-size: 2.8vw;
  font-family: 'Roboto Condensed';
  word-wrap: break-word;
}

@media only screen and (min-width : 1280px) {
  .partner-container{
    width: calc(100vw * var(--global-content-width) * (1/var(--partners-per-row)) - 30px);
  }
  .partner-box{
    height: calc(100vw * var(--global-content-width) * (1/var(--partners-per-row)) - 30px);
    margin-bottom: 15px;
  }
  .partner-name{
    font-size: 1vw;    
  }
}
/* HOME GENERAL */

.home-content{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 20px;
}

/* PARTNERS */

.home-partners{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.home-partners-content{
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap !important;
}

/* APP STORE */

.home-app-store{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.home-app-store-content{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 4.5vw;
  font-family: 'Roboto Condensed';
  text-align: center;
}

.home-app-store-logos{
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  margin-top: 25px;
}

.home-app-store-logos-google{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  width: 45%;
}

.home-app-store-logos-apple{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  width: 45%;
  font-weight: bold;
  font-size: 2.6vw;
}

.home-app-store-logo{
  width: 100%;
}

.home-app-store-logos-apple > span{
  margin-top: 5px;
  background-color: var(--color-blue);
  color: var(--color-white);
  padding: 5px 10px 5px 10px;  
  border-radius: 5px;
}

@media only screen and (min-width : 1280px) {  
  
  /* PARTNERS */

  .home-partners{
    margin-bottom: 0px;
  }

  /* APP STORE */

  .home-app-store{
    margin-bottom: 25px;
  }

  .home-app-store-content{  
    text-align: left;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.6vw;  
    line-height: normal;  
  }

  .home-app-store-logos-apple,
  .home-app-store-logos-google{
    font-size: 0.9vw;
    width: 35%;
    margin-top: 10px;
  }
}

.editions-content{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.editions-content > *{
  margin-bottom: 25px;
}
.history-container{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.history-content > *{
  margin-bottom: 25px;
}
.contact-and-localization-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 100%;
}

.contact-container,
.localization-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 100%;
}

.contact-content,
.localization-content{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.contact-label,
.contact-input,
.contact-textarea{
  width: 100%;  
}

.contact-input,
.contact-textarea{
  border: 2px solid var(--color-light-blue);
  border-radius: 4px;
  padding: 5px;
}

.contact-content > .contact-input{
  margin-bottom: 10px;
}

.contact-label{
  font-weight: 400;
}

.contact-buttons{
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.contact-buttons > button{
  color: var(--color-white);
  font-family: 'Roboto Condensed';
  font-weight: bold;
  padding: 8px 15px 8px 15px;
  border: none;
}

#button-whatsapp{  
  background-color: var(--color-whatsapp);
} 

#button-whatsapp:active{  
  background-color: var(--color-dark-whatsapp);
} 

#button-send-message{  
  background-color: var(--color-dark-blue);
} 

#button-send-message:active{  
  background-color: var(--color-blue);
} 

.map-marker-icon{
  width: 4vw;
}

.localization-address{
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
  font-style: normal;
}

@media only screen and (min-width : 1280px) {
  .contact-and-localization-container{
    flex-flow: row;
    align-items: flex-start;
  }

  .contact-input,
  .contact-textarea{
    padding: 0;
  }

  .contact-buttons{
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .map-marker-icon{
    width: 2vw;
  }
}
