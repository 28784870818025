.header-container{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background-color: var(--color-brand-blue);
  height: 75px;
}

.header-logo{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.header-logo > img{
  height: 40px;
}

.header-logo > span{
  font-family: 'Roboto Condensed';
  font-weight: bold;
  color: var(--color-light-blue);
  font-size: 12px;
}

.header-menu-container{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background-color: var(--color-light);
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.18);
  font-family: 'Roboto Condensed';  
  height: 30px;
  width: 100%;
}

.header-menu{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  font-size: 14px;
}

@media only screen and (min-width : 1280px) {
  .header-container{
    height: 130px;
  }

  .header-logo > img{   
    height: 80px;
  }

  .header-logo > span{
    font-size: 18px;
  }

  .header-menu-container{
    height: 45px;
    box-shadow: 2px 0px 5px 4px rgba(0, 0, 0, 0.18);
  }

  .header-menu-container{
    height: 2.8vw;
  }

  .header-menu{
    width: 50%;
    font-size: 1.2vw;
  }

  .header-menu-item:hover{
    font-weight: bold;
  }
}