.edition-content{
  display: flex;
  flex-flow: row;
  font-family: 'Roboto Condensed';
  color: var(--color-black);
}

.edition-thumbnail{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-right: 20px;
}

.edition-thumbnail > a > img{
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 100%;
}

.edition-details{
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  max-width: 50%;
  max-height: 100%;
}

.edition-publication-date{
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  font-weight: bold;
  font-size: 2.8vw;  
  max-height: 10%;
}

.edition-publication-date > span{
  margin-bottom: 2px;
}

.edition-publication-date-hr{
  background-color: var(--color-dark-blue);
  height: 2px;
  width: 90%;
}

.edition-title{
  font-weight: bold;
  font-size: 4.5vw;
  max-height: 80%;
  overflow-wrap: break-word;
  overflow-y: hidden;
  overflow-x: hidden;
}

.edition-read-it{
  font-weight: bold;
  font-size: 3.5vw;
  color: var(--color-dark-blue);
  max-height: 10%;
}

@media only screen and (min-width : 1280px) {
  .edition-content{
    justify-content: space-around;
  }
  .edition-thumbnail{
    width: 30%;
    margin-left: 35px;
  }
  .edition-details{
    width: 70%;
    margin-right: 35px;
  }
  .edition-publication-date{
    font-size: 1.5vw; 
  }
  .edition-publication-date-hr{
    height: 4px;
    width: 75%;
  }
  .edition-title{  
    font-size: 2.8vw;    
  }
  .edition-read-it{
    font-size: 2vw;
  }
}